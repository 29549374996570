import { withSize as npmWithSize } from 'react-sizeme'

// https://github.com/ctrlplusb/react-sizeme#configuration
const withSize = npmWithSize({
  monitorHeight: true,
  refreshRate: 500, // default 16 and recommended no lower than 16
  noPlaceholder: true,
})

export default withSize
